<template>
  <div class="container">
    <h2 class="title is-2">{{$t('My transactions')}}</h2>
    <p class="pb-5">{{$t('transactions_explain')}}</p>
    <h3 class="title is-5 has-text-right">{{$t('Your balance')}} : {{user.balance.toFixed(2)}} <img class="picto credit" src="/credit.png"></h3>
    <table class="transactions">
      <thead>
        <th>{{$t('Date')}}</th>
        <th>{{$t('Reason')}}</th>
        <th >{{$t('Amount')}}</th>
      </thead>
      <tbody>
        <tr v-for="transaction in transactions">
          <td>
            {{new Date(transaction.date).toLocaleDateString()}}
            <small>{{new Date(transaction.date).toLocaleTimeString()}}</small>
          </td>
          <td>{{$t("reason_" + transaction.reason)}}</td>
          <td class="has-text-right" :class="{'amount-positive' : transaction.amount>=0, 'amount-negative' : transaction.amount<0}">
            {{transaction.amount.toFixed(2)}} <img class="picto credit" src="/credit.png">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'transactions',

  data() {
    return {
      transactions: []
    }
  },

  computed: {
    user: function () {
      return this.$root.$data.user;
    }
  },

  created() {
      axios.get('/api/user/transactions')
      .then(res => {
        this.transactions = res.data.sort((a,b) => b.date.localeCompare(a.date));
      })
      .catch(_ => {});
  }
}
</script>
