<template>
  <div class="container">
    <h2 class="title is-2">{{$t('Change log')}}</h2>
    <div class="content from-markdown" ref="md">
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MarkdownIt from 'markdown-it';

export default {
  name: 'changelog',

  mounted() {
    this.renderMarkdown();
  },

  methods: {
    renderMarkdown() {
      axios.get('/CHANGELOG.md')
      .then(changelog => {
        this.$refs.md.innerHTML = new MarkdownIt().render(changelog.data);
      })
      .catch(_ => {});
    }
  },

}
</script>
