<template>
  <form @submit.prevent="submit()" class="grid">
      <h2 class="title is-4">
       <img class="picto" src="/present.png">
       {{$t('Give a key')}}
      </h2>
      <label class="label" for="game"><span>{{$t('Game')}}</span></label>
      <div class="control">
        <input v-model="game" id="game" type="text" class="input" list="gameList" autocomplete="off" required>
      </div>
      <datalist id="gameList">
        <option v-for="option in gameOptions" :value="option" />
      </datalist>
      <label class="label" for="key"><span>{{$t('Key')}}</span></label>
      <div class="control">
        <input v-model="key" id="key" type="text" class="input" autocomplete="off" required>
      </div>
      <div class="actions">
        <input type="submit" class="button is-primary" :value="$t('Send')">
      </div>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'add-key-form',

  data() {
    return {
      game: '',
      key: '',
      gameOptions: [],
      timeoutID: null
    }
  },

  watch: {
    game: function (newGame) {
      if (this.timeoutID) clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        axios.get('/api/game/list/' + newGame)
        .then(res => {
          this.gameOptions = res.data;
        })
        .catch(_ => {
          this.gameOptions = [];
        });
      }, 300);

    }
  },

  methods: {
    submit() {
      axios.post('/api/game/add', {
        game: this.game,
        key: this.key
      })
      .then(_ => {
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Key added. Thank you for your participation'
        });
        this.game = '';
        this.key = '';
        this.$emit('added');
      })
      .catch(err => {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data
        });
      });

    }
  }
}
</script>


