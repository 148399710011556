<template>
  <transition name="fade" v-on:after-leave="$emit('done')" appear>
  <div class="toast notification" v-if="isVisible">
    <span class="emote"></span>
    <span class="text">
      <span><slot></slot></span>
    </span>
    <button class="delete" @click="isVisible = false"></button>
  </div>
  </transition>
</template>

<style>
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: scaleY(.1);
  }
</style>

<script>
export default {
  name: 'toast',

  data() {
    return {
      isVisible: true
    }
  },

  created() {
    setTimeout(() => {this.isVisible = false}, 5000);
  }
}
</script>
