<template>
  <div class="container">
    <div class="modal" :class="{'is-active': activeGame}">
      <div class="modal-background" @click="hideModal()"></div>
      <div class="modal-card is-fullwidth">
        <header class="modal-card-head bg-primary">
          <div class="container bg-primary">
            <p class="modal-card-title fg-primary">
              {{$t('Buy')}} <b>{{activeGame.name}}</b> {{$t('for')}} <b>{{activeGame.price}}<img class="picto credit" src="/credit.png"></b> ?
            </p>
            <button class="delete" @click="hideModal()"></button>
          </div>
        </header>
        <section class="modal-card-body bg-blanc" v-if="activeGame.donors">
          <div class="container bg-blanc">
            <p v-if="activeGame.donors.length == 1">
                {{$t('key donated by')}} <b>{{activeGame.donors[0].username}}</b> !
            </p>
            <p v-else>
              {{$t('there is x donors', {count:activeGame.donors.length})}}.
              <br/>{{$t('choose donator')}} :
              <span class="select">
                <select class="bg-primary fg-primary">
                  <option>{{$t('let it be random')}}</option>
                  <option v-model="donorId" v-for="donor in activeGame.donors" :value="donor._id">{{donor.username}}</option>
                </select>
              </span>
            </p>
            <div v-if="$root.$data.user.balance < activeGame.price">
              <p>{{$t('not enough credit get more')}}</p>
              <button class="button is-primary" @click="hideModal()">{{$t('Cancel')}}</button>
            </div>
            <div v-else>
              <p>{{$t('Your final balance')}} : {{$root.$data.user.balance - activeGame.price}} <img class="picto credit" src="credit.png"></p>
              <button class="button is-primary" :class="{'is-loading': isLoading}" @click="buyGame(activeGame)">{{$t('ConfirmBuy')}}</button>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="search">
      <h3 class="title is-4">{{$t('Search in games')}}</h3>
      <div class="control">
        <input v-model="searchTerm" id="searchTerm" type="text" class="input" autocomplete="off">
      </div>
      <p class="rules pb-5">{{$t('Search rules')}}</p>
      <div class="level">
        <div class="level-left">
          <div class="filters" v-if="appliedFilters">
            <div class="field is-horizontal" v-for="filter in filters" v-if="filter.applied" :class="{'is-danger': !filter.isValid}">
              <div class="field-label is-small">
                <label class="label">
                  {{$t(filter.name)}}
                </label>
              </div>
              <div class="field-body">
                <div class="control" v-if="filter.arg=='number'">
                  <input class="input is-small" type="number" v-model="filter.value" :class="{'is-danger': !filter.isValid}">
                </div>
                <div class="control" v-else-if="filter.arg=='date'">
                  <input class="input is-small" type="date" v-model="filter.value" :class="{'is-danger': !filter.isValid}">
                </div>
                <div class="control" v-else-if="filter.arg=='options'">
                  <template v-for="opt in filter.list">
                    <input class="is-checkradio is-small" type="checkbox" :id="filter.name+'-'+opt" value="opt" v-model="filter['value_'+opt]">
                    <label :for="filter.name+'-'+opt"">{{opt}}</label>
                  </template>
                </div>
                <div class="control" v-else>
                  <input class="input is-small" type="text" v-model="filter.value" :class="{'is-danger': !filter.isValid}">
                </div>
              </div>
              <button class="delete" @click="toggleFilter(filter)">{{$t('Remove')}}</button>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="dropdown is-right is-hoverable" v-if="availableFilters" ref="addFilterDD">
            <div class="dropdown-trigger">
              <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu" @click="$refs.addFilterDD.classList.toggle('is-active')">
                <span>{{$t('Add filter')}}</span>
                <span class="dropdown-arrow"></span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content has-text-left">
                <a class="dropdown-item" v-for="filter in filters" v-if="!filter.applied" @click="toggleFilter(filter)">
                  {{$t(filter.name)}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-results-header bg-primary fg-primary level">
      <div class="level-left">
        <span v-if="isSearchValid">
          {{$tc('X games matches', filteredGames.length, {count: filteredGames.length, total: allGames.length})}}
        </span>
        <span v-else-if="showAll">
          {{$t('All games')}} ({{allGames.length}}) - <a @click="showAll=false">{{$t('Show recent')}}</a>
        </span>
        <span v-else>
          {{$t('Latest games')}} - <a @click="showAll=true">{{$t('Show all')}} ({{allGames.length}})</a>
        </span>
      </div>
      <div class="level-right">
        <div class="select">
          <select v-model="activeSort">
            <option v-for="sortOption in sortOptions" :value="sortOption">{{$t('sort_'+sortOption.name)}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="games">
      <game-thumb v-for="game in filteredGames" v-on:click="showGame(game)" v-bind:game="game" class="is-clickable"></game-thumb>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import GameThumb from './utils/GameThumb';

export default {
  name: 'home',

  components: {
    'game-thumb': GameThumb
  },

  data() {
    const sortOptions = [
      { name: "price_asc", sortCb: (a,b) => a.price - b.price },
      { name: "price_desc", sortCb: (a,b) => b.price - a.price },
      { name: "name_a_z", sortCb: (a,b) => a.name.localeCompare(b.name) },
      { name: "name_z_a", sortCb: (a,b) => b.name.localeCompare(a.name) },
      { name: "date_asc", sortCb: (a,b) => a.oldestKeyDate.localeCompare(b.oldestKeyDate) },
      { name: "date_desc", sortCb: (a,b) => b.oldestKeyDate.localeCompare(a.oldestKeyDate), default: true },
      { name: "count_asc", sortCb: (a,b) => a.count - b.count },
      { name: "count_desc", sortCb: (a,b) => b.count - a.count },
    ];

    let filterDefinitions = [
      {
        name: "price_lower_than", arg: "number",
        checkIfValid: f => !isNaN(Number(f.value)) && Number(f.value) > 0,
        applyOn: (f, array) => array.filter(g => g.price <= Number(f.value))
      },
      {
        name: "price_greater_than", arg: "number",
        checkIfValid: f => !isNaN(Number(f.value)) && Number(f.value) > 0,
        applyOn: (f, array) => array.filter(g => g.price >= Number(f.value))
      },
      { name: "platform", arg: "options", list:["windows","mac","linux"],
        checkIfValid: f => f.list.filter(o => (f['value_'+o])).length,
        applyOn: (f, array) => array.filter(g => f.list.filter(o => (f['value_'+o])).every(o => g.platforms[o]))
      },
      {
        name: "added_after", arg: "date",
        checkIfValid: f => f.value && !isNaN(new Date(f.value).getSeconds()),
        applyOn: (f, array) => array.filter(g => new Date(g.oldestKeyDate) >= new Date(f.value))
      },
      {
        name: "keys_available", arg: "number",
        checkIfValid: f => !isNaN(Number(f.value)) && Number(f.value) > 0,
        applyOn: (f, array) => array.filter(g => g.count >= Number(f.value))
      },
    ];
    filterDefinitions.forEach(f => {
      f.applied = false;
      f.isValid = true;
    });

    return {
      filters: filterDefinitions,
      sortOptions: sortOptions,
      activeSort: sortOptions.find(so => so.default),
      availableFilters: true,
      appliedFilters: false,
      searchTerm: '',
      isSearchValid: false,
      filteredGames: [],
      allGames: [],
      lastGames: [],
      showAll: false,
      activeGame: false,
      donorId: undefined,
      isLoading: false
    };
  },

  created() {
    this.updateGames();
  },

  watch: {
    filters: {
      handler(val, oldVal) {
        this.scheduleFiltering();
      },
      deep: true
    },
    activeSort() {
      this.scheduleFiltering();
    },
    searchTerm() {
      this.scheduleFiltering();
    },
    showAll() {
      this.scheduleFiltering();
    },
  },

  methods: {
    updateGames() {
      axios.get('/api/game/all')
      .then(games => {
        this.allGames = games.data
            .filter(g => g.price > 0)
            .sort((a, b) => b.oldestKeyDate.localeCompare(a.oldestKeyDate));
        this.lastGames = this.allGames.slice(0,12);
        this.searchTerm = "";
        this.filterGames();
      })
      .catch(_ => {});
    },

    scheduleFiltering() {
      if (this.timeoutID) clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(this.filterGames, 300);
    },

    toggleFilter(filter) {
      filter.applied = !filter.applied;
      this.availableFilters = this.filters.some(f => !f.applied);
      this.appliedFilters = this.filters.some(f => f.applied);
      this.scheduleFiltering();
    },

    applyTextSearch(pool, term) {
      if (term.length <= 2) return pool;
      this.isSearchValid = true;
      term = term
          .replace(/[^\d\w\s?*]/g,'.')
          .replace(/[\s*]+/g,'.+')
          .replace(/\?/g,'.+');
      let searchRE = new RegExp(term, 'i');
      return pool.filter(g => 1 + g.name.search(searchRE));
    },

    filterGames() {
      this.isSearchValid = false;
      let pool = this.applyTextSearch(this.allGames, this.searchTerm.trim());

      this.filters.forEach(f => {
        if (!f.applied) return;
        f.isValid = f.checkIfValid(f);
        if (!f.isValid) return;
        this.isSearchValid = true;
        pool = f.applyOn(f, pool);
      });

      if (!this.isSearchValid && !this.showAll) pool = this.lastGames;
      if (this.activeSort) pool.sort(this.activeSort.sortCb);
      this.filteredGames = pool;
    },

    hideModal() {
      this.activeGame = false;
    },

    showGame(game) {
      this.activeGame = game;
      axios.get('/api/game/donors/' + game._id)
      .then(res => {
        this.$set(this.activeGame, 'donors', res.data);
      })
      .catch(_ => {});
    },

    buyGame(game) {
      this.isLoading = true;
      this.donorId = undefined;
      axios.post('/api/game/buy', {
        userId: this.donorId,
        game: game._id
      })
      .then((res) => {
        this.isLoading = false;
        this.hideModal();
        this.$root.$data.user.balance = res.data;
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Enjoy your new game!'
        });
        this.$router.push('my-keys/acquired');
      })
      .catch(err => {
        this.isLoading = false;
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data || 'Error'
        });
      });
    }
  }

};
</script>

