import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';

import App from './App';
import Welcome from './Welcome';
import Join from './Join';
import Home from './Home';
import MyKeys from './MyKeys';
import Profile from './Profile';
import Transactions from './Transactions';
import Support from './Support';
import EULA from './EULA';
import Changes from './Changes';

import './main.scss';
import messages from './utils/i18n.json';

const locale = 'fr';

let user;

axios.get('/api/sign').then(res => {

  if (res.data.username) {
    user = res.data;
  }

}).catch(_ => {
}).finally(() => {

  function redirectIfNotJoined(to, from, next) {
    if (!user) {
      next('/welcome');
      return;
    } else if (!user.joinedAt) {
      next('/join');
      return;
    }
    next();
  }

  function redirectIfNotAuthenticated(to, from, next) {
    if (!user) {
      next('/welcome');
      return;
    }
    next();
  }

  function redirectIfJoined(to, from, next) {
    if (user.joinedAt) {
      next("/");
      return;
    }
    next();
  }

  Vue.use(VueRouter);
  Vue.use(VueI18n);

  const router = new VueRouter({
    routes: [
      {path: '/', redirect: '/home'},
      {path: '/home', component: Home, beforeEnter: redirectIfNotJoined},
      {path: '/my-keys/:sectionRoute?', component: MyKeys, beforeEnter: redirectIfNotJoined},
      {path: '/profile', component: Profile, beforeEnter: redirectIfNotAuthenticated},
      {path: '/transactions', component: Transactions, beforeEnter: redirectIfNotAuthenticated},
      {path: '/support', component: Support, beforeEnter: redirectIfNotAuthenticated},
      {path: '/join', component: Join, beforeEnter: redirectIfJoined},
      {path: '/welcome', component: Welcome},
      {path: '/changes', component: Changes},
      {path: '/eula', component: EULA}
    ],
    mode: 'history'
  });

  const i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages
  });

  let data = {
    user,
    notifications: [],
    locale
  };


  new Vue({
    data,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});
