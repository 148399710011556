<template>
  <div class="container">
    <h2 class="title is-2">{{$t('Join')}}</h2>
    <p class="pb-5">
      {{$t('Join intro')}}
    </p>
    <button class="button is-primary" :class="{'is-loading': isLoading}" @click="join">{{$t('Join for')}} {{price}}<img class="picto credit" src="credit.png" v-if="!isLoading"></button>

    <h3 class="title is-3 mt-5">{{$t('Preview title')}}</h3>
    <p class="pb-5">
      {{$t('Preview intro')}}<br>
      <small><i>{{$t('Preview disclaimer')}}</i></small>
    </p>

    <div class="games">
      <game-thumb v-for="game in gamesPreview" v-bind:game="game"></game-thumb>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import GameThumb from './utils/GameThumb';

export default {
  name: 'join',

  components: {
    'game-thumb': GameThumb
  },

  data() {
    return {
      gamesPreview: [],
      price: PRICING_MEMBERSHIP,
      isLoading: false
    }
  },

  created() {
    this.updateGames();
  },

  methods: {
    updateGames() {
      axios.get('/api/game/preview')
      .then(games => {
        this.gamesPreview = games.data;
      })
      .catch(_ => {});
    },

    join() {
      this.isLoading = true;
      axios.post('/api/user/join')
      .then((res) => {
        this.isLoading = false;
        this.$root.$data.user.joinedAt = new Date();
        this.$root.$data.user.balance = res.data;
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Welcome'
        });
        this.$router.push('/');
      })
      .catch(err => {
        this.isLoading = false;
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data || 'Error'
        });
      })
    }
  }
}
</script>
