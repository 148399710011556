<template>
  <body>
    <section class="hero is-primary is-bold" v-if="!user">
      <div class="hero-body">
        <div class="container">
            <h1 class="title is-1"><img class="logo" src="/gestioncles.png"><span>{{$t('Website')}}</span></h1>
        </div>
      </div>
    </section>
    <nav class="navbar is-primary" role="navigation" v-if="user">
      <div class="container">
        <div class="navbar-brand">
          <router-link to="/" class="logo">
            <img src="/gestioncles.png">
            <h1 class="title">{{$t('Website')}}</h1>
          </router-link>
          <a role="button" class="navbar-burger" :class="{'is-active': showMenu}" @click="showMenu = !showMenu">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{'is-active': showMenu}">
          <div class="navbar-start" v-if="user">
            <router-link v-if="user.joinedAt" to="/home" class="navbar-item">{{$t('Home')}}</router-link>
            <router-link v-if="user.joinedAt" to="/my-keys" class="navbar-item">{{$t('My keys')}}</router-link>
          </div>
          <div class="navbar-end">
            <a v-if="user.joinedAt" class="navbar-item addkey" @click="showAddKey=true">
              <img class="picto space-right" src="/present.png">{{$t('Give a key')}}
            </a>
            <div class="navbar-item" v-if="user">
              <span>{{$t('Balance')}}: {{user.balance.toFixed(0)}} <img class="picto credit" src="/credit.png"></span>
            </div>
            <div class="navbar-item has-dropdown is-hoverable" v-if="user">
              <a class="navbar-link">{{user.username}}</a>
              <div class="navbar-dropdown is-right">
                <router-link v-if="!user.joinedAt" to="/join" class="navbar-item">{{$t('Join')}}</router-link>
                <router-link to="/profile" class="navbar-item">{{$t('Profile')}}</router-link>
                <router-link to="/transactions" class="navbar-item">{{$t('Transactions')}}</router-link>
                <router-link to="/support" class="navbar-item">{{$t('Support')}}</router-link>
                <hr class="navbar-divider">
                <a class="navbar-item" @click="signOut">{{$t('Logout')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container toast-container">
      <toast :class="message.class" v-for="message in $root.$data.notifications" v-on:done="notificationDone(message)">{{$t(message.text)}}</toast>
    </div>

    <div class="container banner is-size-6 mt-3" v-if="validationPending > 0">
      <router-link class="p-1" to="/my-keys/acquired">{{$t('key waiting validation')}}</router-link>
    </div>

    <section class="section">
      <div class="modal" :class="{'is-active': showAddKey}">
        <div class="modal-background" @click="showAddKey=false"></div>
        <div class="modal-card ">
          <add-key-form v-on:added="keyAdded"></add-key-form>
        </div>
      </div>

      <router-view ref="mainView" v-on:keyValidated="checkPendingValidation()"></router-view>
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <router-link to="/changes"><strong>{{$t('Website')}} v{{version}}</strong></router-link>
          by <a href="https://www.zankia.fr">zankia</a>
          •
          Icons by <a href="https://www.svgrepo.com/">SVG Repo</a>
          •
          <router-link to="/eula">{{$t('EULA')}}</router-link>
        </p>
      </div>
    </footer>
  </body>
</template>

<script>
import axios from 'axios';
import Login from './Login';
import Toast from './utils/Toast.vue';
import AddKeyForm from './AddKeyForm';

export default {
  name: 'app',

  components: {
    'add-key-form': AddKeyForm,
    'toast': Toast
  },

  data() {
    return {
      version: PROJECT_VERSION,
      validationPending: 0,
      showAddKey: false,
      showMenu: false
    }
  },

  computed: {
    user: function () {
      return this.$root.$data.user;
    }
  },

  watch: {
    '$route' () {
      document.activeElement.blur();
      this.showMenu = false
    }
  },

  created() {
    this.checkPendingValidation();
  },

  methods: {
    keyAdded() {
      this.showAddKey=false;
      if ( this.$refs.mainView.updateKeys ) this.$refs.mainView.updateKeys();
      if ( this.$router.currentRoute.fullPath != '/my-keys/avail') {
        this.$router.push('/my-keys/avail');
      }
    },

    checkPendingValidation() {
      if (!this.user || !this.user.joinedAt) return;
      axios.get('/api/game/self/pending')
      .then(res => this.validationPending = res.data.count)
      .catch(_ => {});
    },

    notificationDone(message) {
      let index = this.$root.$data.notifications.findIndex( (m) => m == message );
      if (index < 0) return;
      this.$root.$data.notifications.splice(index, 1);
    },

    signOut() {
      document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$root.$data.user = undefined;
      this.$root.$data.notifications = [];
      this.validationPending = 0;
      this.$root.$router.push('/welcome');
    }
  }
}
</script>
