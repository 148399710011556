<template>
  <div class="container" v-if="$root.$data.user">
    <h2 class="title is-2">{{$t('FAQ')}}</h2>
    <p>{{$t('FAQ intro')}}</p>
    <div class="content">
      <ul>
        <li v-for="x in 5">
          <h3 class="is-size-4 has-text-weight-bold">{{$t('FAQ question ' + x)}}</h3>
          <p class="has-text-justified pb-5">{{$t('FAQ answer ' + x)}}</p>
        </li>
      </ul>
    </div>
    <h2 class="title is-2">{{$t('Contact')}}</h2>
    <form @submit.prevent="sendMail()" class="grid large">
      <h3 class="title is-4">
        {{$t('Contact mode')}}
      </h3>
      <label class="label"><span>
        {{$t('Choose one')}}
      </span></label>
      <div class="control group field">
        <input class="is-checkradio" type="radio" value="email" v-model="type" @change="userContact = user.email" id="chk_email">
        <label for="chk_email">{{$t('Email')}}</label>
        <input class="is-checkradio" type="radio" value="discord" v-model="type" @change="userContact = user.discord" id="chk_discord">
        <label for="chk_discord">Discord</label>
        <input class="is-checkradio" type="radio" value="twitch" v-model="type" @change="userContact = user.username" id="chk_twitch">
        <label for="chk_twitch">Twitch</label>
      </div>
      <label class="label" for="email" v-if="type === 'email'"><span>{{$t('Email')}}</span></label>
      <div class="control" v-if="type === 'email'">
        <input v-model="userContact" id="email" type="email" class="input" placeholder="user@mail.com" required>
      </div>
      <label class="label" for="discord" v-if="type === 'discord'"><span>{{$t('Discord tag')}}</span></label>
      <div class="control" v-if="type === 'discord'">
        <input v-model="userContact" id="discord" type="text" class="input" placeholder="user#0000" pattern=".*#[0-9]{4}" required>
      </div>
      <div class="actions">

        <input id="setAsDefault" type="checkbox" v-model="setAsDefault" class="switch is-rounded">
        <label for="setAsDefault">{{$t('Remember')}}</label>

      </div>
      <h3 class="title is-4">
       {{$t('Contact message')}}
      </h3>
      <label class="label" for="summary"><span>{{$t('Summary')}}</span></label>
      <div class="control">
        <input v-model="summary" id="summary" type="text" class="input" minlength="3" maxlength="100" required>
      </div>
      <label class="label large" for="request"><span>{{$t('Request')}}</span></label>
      <div class="control large">
        <textarea v-model="message" id="request" class="textarea" rows="3" minlength="25" required></textarea>
      </div>
      <div class="actions">
       <input type="submit" class="button is-primary" :value="$t('Send')">
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'support',

  data() {
    return {
      userContact: '',
      type: '',
      setAsDefault: false,
      summary: '',
      message: ''
    }
  },

  computed: {
    user: function () {
      return this.$root.$data.user;
    }
  },

  created() {
    this.type = this.user.preferredContactType;
    switch (this.type) {
      case 'email':
        this.userContact = this.user.email;
        break;
      case 'discord':
        this.userContact = this.user.discord;
        break;
    }
  },

  methods: {
    sendMail() {
      if (this.type === '') {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: 'Please select a way of contact'
        });
        return;
      }
      axios.post('/api/support', {
        userContact: this.userContact,
        title: this.summary,
        message: this.message
      })
      .then(_ => {
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Support request sent'
        });
        this.message = '';
      })
      .catch(err => {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data
        });
      });

      if (this.setAsDefault) {
        this.setDefault();
      }

    },

    setDefault() {
      let postData = {preferredContactType: this.type };
      switch (this.type) {
        case 'email':
          postData.email = this.userContact;
          this.userUpdate(postData, () => {this.user.email = this.userContact});
          break;
        case 'discord':
          postData.discord = this.userContact;
          this.userUpdate(postData, () => {this.user.discord = this.userContact});
          break;
      }
    },

    userUpdate(postData, callback) {
      axios.post('/api/user/update', postData)
      .then(_ => {
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Changes saved'
        });
        callback();
      })
      .catch(err => {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data
        });
      });
    }

  }
}
</script>

