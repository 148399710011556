<template>
  <div class="container">
    <h2 class="title is-2">{{$t('End User Licence Agreement')}}</h2>
    <p>{{$t('EULA intro')}}</p>
    <div class="content">
      <ul>
        <li class="has-text-justified" v-for="x in 7">{{$t('EULA item ' + x)}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eula'
}
</script>

