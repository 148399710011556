<template>
  <div class="container">
    <h2 class="title is-2">{{$t('Profile')}}</h2>

    <form @submit.prevent="updateInfos()" class="grid large">
      <h3 class="title is-4">
        {{$t('General information')}}
      </h3>

      <label class="label" for="email"><span>{{$t('Email')}}</span></label>
      <div class="control">
        <input v-model="email" id="email" type="email" class="input" placeholder="user@mail.com">
      </div>

      <label class="label" for="discord"><span>{{$t('Discord tag')}}</span></label>
      <div class="control">
        <input v-model="discord" id="discord" type="text" class="input" placeholder="user">
      </div>
      <p class="control">{{$t('To receive notification on discord, you must have join this')}} <a href="https://discord.gg/grA3TM8" target="_blank">discord.gg/grA3TM8</a></p>

      <h3 class="title is-4 pt-5">
        {{$t('Notifications settings')}}
      </h3>

      <template v-for="medium in notificationMedia">

        <h4 class="title is-5">
          {{$t('by_'+medium)}}
        </h4>

        <div class="control field" v-for="subject in notificationSubjects">
          <input type="checkbox" :id="'notif_'+medium+subject" :checked="getNotificationStatus(medium,subject)" class="switch is-rounded" @change="toggleNotification(medium,subject)">
          <label :for="'notif_'+medium+subject""><span>{{$t('notification_'+subject)}}</span></label>
        </div>

      </template>

      <div class="actions">
        <input type="submit" class="button is-primary" :value="$t('Save changes')">
      </div>

    </form>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'profile',

  data() {
    return {
      notificationMedia: ['email', 'discord'], // TODO : 'twitch'
      notificationSubjects: ['gamesAdded','keySold','keyValidated'],
      email: '',
      discord: '',
      notifications: {},
    }
  },

  created() {
    this.email = this.user.email;
    this.discord = this.user.discord;
    this.notifications = this.user.notifications;
  },

  computed: {
    user: function () {
      return this.$root.$data.user;
    }
  },

  methods: {
    getNotificationStatus(medium, subject) {
      if (!this.notifications) this.notifications = {};
      if (!this.notifications[subject]) this.notifications[subject] = [];
      return this.notifications[subject].some(m => m==medium);
    },

    toggleNotification(medium, subject) {
      if ( this.getNotificationStatus(medium, subject) ) {
        this.notifications[subject] = this.notifications[subject].filter(m => m!=medium);
      } else {
        this.notifications[subject].push(medium);
      }
    },

    updateInfos() {
      axios.post('/api/user/update', {
        notifications: this.notifications,
        discord: this.discord,
        email: this.email
      })
      .then(_ => {
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Changes saved'
        });
        this.user.email = this.email;
        this.user.discord = this.discord;
        this.user.notifications = this.notifications;
      })
      .catch(err => {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data
        });
      });
    },

  }
}
</script>
