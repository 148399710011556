<template>
  <div class="container">
    <h2 class="title is-2">{{$t('Welcome')}}</h2>
    <p class="pb-5">
      {{$t('Welcome text 1')}}
      <br/>{{$t('Welcome text 2')}}
    </p>
    <login></login>
  </div>
</template>

<script>
import Login from './Login';

export default {
  name: 'welcome',

  components: {
    'login': Login
  },
}
</script>
