<template>
  <div class="buttons">
    <a :href="twitchURI" class="button is-twitch" :class="{'is-loading': isLoading}" @click="isLoading = true">{{$t('Login with Twitch')}}</a>
  </div>
</template>

<script>
export default {
  name: 'login',

  data() {
    return {
      isLoading: false,
      twitchURI: 'https://id.twitch.tv/oauth2/authorize?response_type=code&client_id='
          + TWITCH_API_KEY + '&redirect_uri=' + TWITCH_REDIRECT_URI + '/twitch'
    }
  }
}
</script>
