<template>
  <div class="container">

    <h2 class="title is-2">{{$t('My keys')}}</h2>
    <div class="tabs is-medium is-fullwidth">
      <ul>
        <li :class="{'is-active': section === 'acquired'}">
            <router-link to="/my-keys/acquired">
              <img class="picto space-right" src="/achete.png">
              {{$t('Acquired keys')}}
              <span v-if="keysToValidate" class="count bg-primary fg-primary">{{keysToValidate}}</span>
            </router-link>
        </li>
        <li :class="{'is-active': section === 'avail'}">
            <router-link to="/my-keys/avail"><img class="picto space-right" src="/nouveau.png">{{$t('Available keys')}}</router-link>
        </li>
        <li :class="{'is-active': section === 'sold'}">
            <router-link to="/my-keys/sold"><img class="picto space-right" src="/vend.png">{{$t('Sold keys')}}</router-link>
        </li>
      </ul>
    </div>

    <div v-for="key in keys[section]" class="gc-key bg-blanc">

      <img class="image" :src="`/img/${key.gameId}.jpg`" >

      <div class="infos">
        <div class="title is-4">
          {{key.game}}
        </div>
        <ul class="details">
          <li :title="`${key.timeAdded}`" v-if="isOwn(key)">
              {{$t('Date added')}}: {{key.dateAdded}}
          </li>
          <li v-if="isOwn(key) && key.givenTo">
              {{$t('Buyer')}}: {{key.givenTo}}
          </li>
          <li v-if="!isOwn(key)">
              {{$t('Donor')}}: {{key.user}}
          </li>
          <li :title="`${key.timeUpdated}`" v-if="key.updatedAt">
              {{$t('Date sold')}}: {{key.dateUpdated}}
          </li>
          <li :title="`${key.timeValidated}`" v-if="key.validatedAt">
              {{$t('Date validated')}} {{key.dateValidated}},
              {{key.isValid ? $t("key is valid") : $t("key is not valid")}}
          </li>
          <li v-else-if="key.givenTo">
              {{$t("key is awaiting validation")}}
          </li>
        </ul>
        <div v-if="key.revealed" class="actions key">
          <input type="text" :value="key.key" readonly="readonly" :ref="'key_' + key._id">
          <button class="button" @click="copy(key)">{{$t('Copy')}}</button>
        </div>
        <div class="actions" v-if="key.validatable">
          <span class="title is-6">{{$t('Is this key valid')}} ?</span>
          <button class="button is-success is-outlined" @click="validate(key, true)">👍 {{$t('Yes')}}</button>
          <button class="button is-danger is-outlined" @click="validate(key, false)">👎 {{$t('No')}}</button>
        </div>
      </div>
      <div class="actions">
        <button class="button is-danger" v-if="isRemovable(key)" @click="remove(key)">{{$t('Remove')}}</button>
        <button v-if="!key.revealed" class="button is-text" @click="key.revealed=true">{{$t('Reveal key')}}</button>
        <button v-else class="button is-text" @click="key.revealed=false">{{$t('Hide key')}}</button>
      </div>
    </div>

    <div class="is-size-6 gc-list-stats" :class="{'has-text-right' : keys[section].length, 'has-text-centered' : !keys[section].length}">
      {{$tc('Keys shown in ' + section, keys[section].length, {count: keys[section].length})}}
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'my-keys',

  data() {
    return {
      keys: { sold:[], avail:[], acquired:[] },
    }
  },

  computed: {
    keysToValidate: function(){
      return this.keys.acquired.reduce( (acc,cur) => acc += cur.validatable ? 1 : 0 , 0 );
    },
    section: function(){
      return this.$route.params.sectionRoute || 'acquired';
    }
  },

  created() {
    this.updateKeys();
  },

  methods: {
    updateKeys() {
      axios.get('/api/game/self')
      .then(keys => {
        this.keys = { sold:[], avail:[], acquired:[] };
        keys.data.forEach(key => {
          if (key.createdAt) {
            key.dateAdded = new Date(key.createdAt).toLocaleDateString(this.$root.$data.locale);
            key.timeAdded = new Date(key.createdAt).toTimeString(this.$root.$data.locale);
          }
          if (key.updatedAt) {
            key.dateUpdated = new Date(key.updatedAt).toLocaleDateString(this.$root.$data.locale);
            key.timeUpdated = new Date(key.updatedAt).toTimeString(this.$root.$data.locale);
          }
          if (key.validatedAt) {
            key.dateValidated = new Date(key.validatedAt).toLocaleDateString(this.$root.$data.locale);
            key.timeValidated = new Date(key.validatedAt).toTimeString(this.$root.$data.locale);
          }
          key.validatable = this.isValidatable(key);
          key.revealed = false;
          this.keys[this.isOwn(key) ? ( key.givenTo ? "sold" : "avail" ) : "acquired"].push(key) ;
        });
      })
      .catch(_ => {});
    },

    isOwn(key) {
      return key.user === this.$root.$data.user.username
    },

    isRemovable(key) {
      return key.user === this.$root.$data.user.username && !key.givenTo
    },

    isValidatable(key) {
      return key.givenTo === this.$root.$data.user.username && !key.validatedAt
    },

    copy(key) {
      const input = this.$refs['key_' + key._id][0];

      if (!input) {
        return;
      }

      input.select();
      input.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand('copy');
      this.$root.$data.notifications.push({
        class: 'is-success',
        text: 'Key copied to the clipboard.'
      });
    },

    validate(key, valid) {
      axios.post('/api/game/validate', {
        key: key._id,
        isValid: valid
      })
      .then(_ => {
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Key validated.'
        });
        this.$emit('keyValidated');
        key.validatedAt = new Date();
        key.dateValidated = key.validatedAt.toLocaleDateString(this.$root.$data.locale);
        key.timeValidated = key.validatedAt.toTimeString(this.$root.$data.locale);
        key.validatable = false;
        key.isValid = valid;
      })
      .catch(err => {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data
        });
      });
    },

    remove(key) {
      axios.post('/api/game/remove', {
        key: key._id
      })
      .then(_ => {
        this.$root.$data.notifications.push({
          class: 'is-success',
          text: 'Key removed.'
        });
        //remove key from the avail list
        this.keys.avail = this.keys.avail.filter( e => e._id != key._id );
      })
      .catch(err => {
        this.$root.$data.notifications.push({
          class: 'is-danger',
          text: err.response.data
        });
      });
    },

  }

}
</script>

