<template>

  <div class="card" :title="`${game.name}`" @click="$emit('click');">
    <div class="card-image">
      <figure class="image">
        <img :src="`/img/${game._id}.jpg`" :alt="game.name">
      </figure>
    </div>
    <div class="card-content">
      <p class="title is-4">{{game.name}}</p>
    </div>
    <div class="card-footer">
      <span class="keycount"><img class="picto" src="/gestioncles.png"> x{{game.count}}</span>
      <span class="platforms">
        <img class="picto" v-for="platform in ['windows','mac','linux']" v-if="game.platforms[platform]" :src="'/platform-'+platform+'.png'">
      </span>
      <span class="price">{{game.price}} <img class="picto credit" src="/credit.png"></span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'game-thumb',
  props: ['game'],
}
</script>
